import $ from 'jquery'

class LineClamp {
    constructor() {
         var clampSelectors = [
            {
                cssSelector: '.c-upcoming-events .c-cta__text',
                lineLength: 2
            }
        ];

        $.each(clampSelectors, function(index, clamp) {
            $(clamp.cssSelector).each(function(index, element) {
                $clamp(element, {clamp: clamp.lineLength});
            });
        });
    }
}

export default LineClamp