import $ from 'jquery'

class ViewportCalculation {
    constructor() {
        var calculateVhUnitValue = function () {
        var vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', vh + 'px');
        };

        calculateVhUnitValue();

        $(window).on('orientationchange', function () {
            calculateVhUnitValue();
        });
    }
}

export default ViewportCalculation