import SplashScreeen from './modules/SplashScreeen'
import Navigation from './modules/Navigation'
import Offcanvas from './modules/Offcanvas'
import HeaderScroll from './modules/HeaderScroll'
import BookingActivator from './modules/BookingActivator'
// import ResDiaryTrigger from './modules/ResDiaryTrigger'
import ViewportCalculation from './modules/ViewportCalculation'
import LineClamp from './modules/LineClamp'
import OnScroll from './modules/OnScroll'

new SplashScreeen()
new Navigation()
new Offcanvas()
new HeaderScroll()
new BookingActivator()
// new ResDiaryTrigger()
new ViewportCalculation()
new LineClamp()
new OnScroll()

$(() => {
    // Newsletter signup DoB 18+ validation
    const NEWSLETTER_SIGNUP_IDS = []

    $('.c-form--signup form, .c-announcement__form form').each( function() {
        NEWSLETTER_SIGNUP_IDS.push(`#${$(this).attr('id')}`)
    })
        
    $.each(NEWSLETTER_SIGNUP_IDS, (i, NEWSLETTER_SIGNUP_ID) => {
        if ($(`${NEWSLETTER_SIGNUP_ID}`).length > 0) {
            const yearInMs = 3.15576e+10, // Using a year of 365.25 days (because leap years)
                getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / yearInMs),
                isValidDate = dateString => {
                    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
                    return regex.test(dateString);
                }

            $(document).on('change', `${NEWSLETTER_SIGNUP_ID}`, ({target}) => {
                let datePicker = $(target).closest('.c-form').find('.gform-datepicker')[0],
                    drinkChoiceInput = $(target).closest('.c-form').find('.gfield--type-choice .gfield-choice-input[value="Drink"]')[0],
                    formButton = $(target).closest('.c-form').find('.gform_button')[0]
    
                // only run if either the drinks choice or the date field changes
                if (target != datePicker && target != drinkChoiceInput) return
    
                // if the drinks field has been unchecked then restore button to default
                if (target == drinkChoiceInput && !drinkChoiceInput.checked) {
                    return $(formButton).prop('disabled', false).css({'pointer-events': ''})
                }
    
                // don't fire until a valid dd/mm/yyyy date has been inputted - GF will handle formatting
                let inputDate = target.value
                if(!isValidDate(inputDate)) return

                inputDate = inputDate.split('/').reverse().join('-');
    
                let calculatedAge = getAge(new Date(inputDate))

                if(calculatedAge >= 18 || target.disabled) {
                    // reset button to default and remove error
                    $(formButton).prop('disabled', false).css({'pointer-events': ''})
                    $(target).closest('.gfield').removeClass('gfield_error').find('.gfield_validation_message').remove()

                    return
                }
    
                // prevent button from submitting
                $(formButton).prop('disabled', true).css({'pointer-events': 'none'})
    
                let errorMessage = "Sorry, but you are not old enough to subscribe to newsletters regarding 'Drink', please deselect this option from the choices above."
    
                if($(target).closest('.gfield').find('.gfield_validation_message').length > 0) {
                    $(target).closest('.gfield').addClass('gfield_error').find('.gfield_validation_message')[0].innerHTML = errorMessage
                    return
                } else {
                    let errorElement = document.createElement('div')
    
                    errorElement.setAttribute('id', 'validation_message_8_8')
                    errorElement.classList.add('gfield_description', 'validation_message', 'gfield_validation_message')
                    errorElement.innerHTML = errorMessage
    
                    $(target).parent().append(errorElement)
                }
            })
        }
    })
})