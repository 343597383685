import $ from 'jquery'

class Navigation {
    constructor() {
        $('.menu__item--has-children > a').on('click', function(e) {
            e.preventDefault();
            var parentEl = $(this).parent();

            $('.menu__item--has-children').not(parentEl).removeClass('is-active');
            $(this).parent().addClass('is-active');
        });

        $('.c-offcanvas--menu .c-offcanvas__close').on('click', function() {
            setTimeout(function() {
                $('.menu__item--has-children').removeClass('is-active');
                $('.c-offcanvas__sidebar-image').removeClass('is-active');
                $('.c-offcanvas__sidebar-image--default').addClass('is-active');
            }, 500);
        });
    }
}

export default Navigation