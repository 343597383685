import $ from 'jquery'

class BookingActivator {
    constructor() {
        $('a[href="#book"]').on('click', function(e) {
            e.preventDefault();
            $('body').addClass('book-offcanvas-is-visible');
        });
    }
}

export default BookingActivator