import $ from 'jquery'

class Offcanvas {
    constructor() {
        $('.c-nav-primary > .menu > .menu__item').hover(function() {
            var menuItemId = $(this).attr('id');
            var relatedSidebarImageEl = $('.c-offcanvas__sidebar-image.' + menuItemId);

            $('.c-offcanvas__sidebar-image').removeClass('is-active');

            if (relatedSidebarImageEl.length) {
                relatedSidebarImageEl.addClass('is-active');
            } else {
                $('.c-offcanvas__sidebar-image--default').addClass('is-active');
            }
        });

    }
}

export default Offcanvas