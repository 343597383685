import $ from 'jquery'

class HeaderScroll {
    constructor() {
        var checkScrollPosition = function () {
        var headerEl = $('.c-page-header'),
            scrollPosition = $(window).scrollTop(),
            windowWidth = $(window).width(),
            windowHeight = $(window).height(),
            fixedTriggerPosition = (windowHeight/2 - headerEl.height()/2);

            if (windowWidth < 1024) {
                headerEl.addClass('is-fixed');
            } else {
                if (scrollPosition >= fixedTriggerPosition) {
                    headerEl.addClass('is-fixed');
                } else {
                    headerEl.removeClass('is-fixed');
                }
            }
        };

        checkScrollPosition();


        var pageIsScrolling = false;
        var pageScrollTimer;

        var checkScrollAction = function () {
            if (!pageIsScrolling) {
                pageIsScrolling = true;
                $('body').addClass('page-is-scrolling');
            }

            clearTimeout(pageScrollTimer);

            pageScrollTimer = setTimeout(function() {
                pageIsScrolling = false;
                $('body').removeClass('page-is-scrolling');
            }, 400);
        };


        $(window).on('scroll', function () {
            checkScrollPosition();
            checkScrollAction();
        });

        $(window).on('resize', function () {
            checkScrollPosition();
        });


    }


}

export default HeaderScroll